
.app
  text-align: center

.appLogo
  height: 40vmin
  pointer-events: none

@media (prefers-reduced-motion: no-preference)
  .appLogo
    animation: App-logo-spin infinite 10s linear

.appHeader
  min-height: 100vh
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  font-size: calc(10px + 2vmin)
 
@keyframes App-logo-spin
  from
    transform: rotate(0deg)
  to
    transform: rotate(360deg)
