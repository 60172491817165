// Type

@import url('https://fonts.googleapis.com/css2?family=Lato&family=Noto+Sans+Symbols:wght@300;400;500;600;700&family=Zen+Kaku+Gothic+Antique:wght@300;400;500;700&display=swap')

$bodyFontFamily: 'Zen Kaku Gothic Antique', Helvetica, sans-serif
$bodyFontWeight: 400
$bodyFontSize: 1rem
$bodyLetterSpacing: 0.01em
  
// Colors

$eyeBlue: #006EFF
$eyeGold: #E0B258